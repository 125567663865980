@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Montserrat&family=News+Cycle&family=Shadows+Into+Light&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Amatic SC', cursive;
  font-family: 'News Cycle', sans-serif;
  font-family: 'Shadows Into Light', cursive;
  font-family: 'Montserrat', sans-serif;
}
